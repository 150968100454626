:root {
  /* Color Global*/
  --BasicWhite: #FFFFFF;
  --BasicBlack: #000000;
  --Gray10: #FAFAFA;
  --Gray20: #E5E5E5;
  --Gray30: #F4F4F4;
  --Gray40: #BFBFBF;
  --Gray50: #999999;
  --Gray60: #737373;
  --Gray70: #666666;
  --Gray80: #333333;
  --Gray90: #252525;
  --Blue10: #E7ECFE;
  --Blue30: #718EF9;
  --Blue60: #0D3FF5;
  --Blue70: #0938E1;
  --Blue80: #0832C9;
  --Red10: #FDEDED;
  --Red30: #F5A3A3;
  --Red60: #EB4747;
  --Red70: #E6191A;
  --Red80: #CF1617;
  --Orange10: #FFEED6;
  --Orange30: #FFCA80;
  --Orange60: #FF9F1A;
  --Orange70: #FF8E14;
  --Orange80: #FF7B00;
  --Green10: #CFFCED;
  --Green30: #4DE5B3;
  --Green60: #00B277;
  --Green70: #009966;
  --Green80: #008055;
  --Yellow10: #FFFBCC;
  --Yellow30: #FFF580;
  --Yellow60: #FFEB00;
  --Yellow70: #FFD500;
  --Yellow80: #FFBF00;
  --OrangeECI10: #FFF7F3;
  --OrangeECI30: #FFF0E8;
  --OrangeECI60: #FF935B;
  --OrangeECI70: #FF7832;
  --OrangeECI80: #DF520C;
  --GreenECI10: #F4FDEF;
  --GreenECI30: #BEDBA4;
  --GreenECI60: #80B84C;
  --GreenECI70: #4D8131;
  --GreenECI80: #2E5F21;

  --GreenEECI10: #dce9d7;
  --GreenEECI30: #9fd68c;
  --GreenEECI60: #7ac960;
  --GreenEECI70: #52AE32;
  --GreenEECI80: #387f20;

  --GreenEECIM10: #d6eae4;
  --GreenEECIM30: #a9d7c9;
  --GreenEECIM60: #7dcdb3;
  --GreenEECIM70: #4CB692;
  --GreenEECIM80: #22624d;
  /* Color Light */
  --Content1: var(--Gray90);
  --Content2: var(--Gray70);
  --Content3: var(--Gray60);
  --ContentInverse: var(--BasicWhite);
  --ContentHover: var(--Gray80);
  --ContentActive: var(--Gray90);
  --ContentDisabled: var(--Gray40);
  --Background1: var(--BasicWhite);
  --Background2: var(--Gray10);
  --Background3: var(--Gray30);
  --BackgroundInverse: var(--BasicBlack);
  --BackgroundHover: var(--Gray20);
  --BacgroundActive: var(--Gray30);
  --BackgroundDisabled: var(--Gray10);
  --Border1: var(--BasicBlack);
  --Border2: var(--Gray50);
  --Border3: var(--Gray30);
  --BorderInverse: var(--BasicWhite);
  --SemanticHighlighting: var(--GreenEECI70);
  --SemanticDanger: var(--Red60);
  --SemanticWarning: var(--Orange60);
  --SemanticSuccess: var(--Green60);
  --SemanticHighlightingHover: var(--GreenEECI60);
  --SemanticHighlightingActive: var(--GreenEECI80);
  --SemanticDangerHover: var(--Red70);
  --SemanticDangerActive: var(--Red80);
  /* Typography Global */
  --FontFamilyPrimary: "Poppins";
  --FontWeightRegular: 400;
  --FontWeightSemiBold: 700;
  --FontWeightMedium: 800;
  --LetterSpacingDefault: 0%;
  --LetterSpacingNarrow: -3%;
  --LetterSpacingNarrower: -4%;
  --LetterSpacingNarrowest: -6%;
  --FontSize1: 10px;
  --FontSize2: 12px;
  --FontSize3: 14px;
  --FontSize4: 16px;
  --FontSize5: 18px;
  --FontSize6: 20px;
  --FontSize7: 24px;
  --FontSize8: 28px;
  --FontSize9: 32px;
  --FontSize10: 36px;
  --FontSize11: 40px;
  --FontSize12: 48px;
  --FontSize13: 56px;
  --FontSize14: 64px;
  --FontSize15: 72px;
  --FontSize16: 80px;
  /* Object Style */
  --BorderRadiusEECI: 0px;
  --BorderRadiusS: 2px;
  --BorderRadiusM: 4px;
  --BorderRadiusL: 8px;
  --BorderRadiusXL: 32px;
  --BorderRadiusFull: 100px;
  --BorderWidthM: 1px;
  --BorderWidthL: 2px;
  --BorderWidthXL: 4px;
  --ShadowM: 0px 16px 28px rgba(0, 0, 0, 0.02);
  --BlurM: 28px;
  /* Sizing & Spacing */
  --Size1: 4px;
  --Size2: 8px;
  --Size3: 12px;
  --Size4: 16px;
  --Size5: 20px;
  --Size6: 24px;
  --Size7: 32px;
  --Size8: 40px;
  --Size9: 48px;
  --Size10: 56px;
  --Size11: 64px;
  --Size12: 80px;
}

/* Poppins font */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(./../assets/fonts/Poppins-Font/Poppins-Regular.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url(./../assets/fonts/Poppins-Font/Poppins-Italic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url(./../assets/fonts/Poppins-Font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url(./../assets/fonts/Poppins-Font/Poppins-SemiBoldItalic.ttf);
}



body{
  font-family: var(--FontFamilyPrimary) !important;
    .fontSize2 { font-size: 12px; }
    input, select.custom-select, .form-control{
      padding: 0px 16px;
      width: 100%;
      height: 48px;
      background-color: #F4F4F4;
      border-radius: 8px;
      margin: 4px 0px;
      border: none;
        option{
          background: white;
          font-family: var(--FontFamilyPrimary);
          padding: 5px;
          border-bottom: 1px solid gray;
        }
  }
  input:focus, select:focus, .custom-select:focus, .form-control:focus{
    border: 1px solid var(--GreenEECI70);
    box-shadow: none;
    background-color: #F4F4F4;
  }

  textarea.form-control{
    padding: 16px;
  }

  .cursorPointer { cursor: pointer };

  .colorGlobalBasicWhite { color: var(--BasicWhite); }
  .colorGlobalBasicBlack { color: var(--BasicBlack); }
  .colorGlobalGray10 { color: var(--Gray10); }
  .colorGlobalGray20 { color: var(--Gray20); }
  .colorGlobalGray30 { color: var(--Gray30); }
  .colorGlobalGray40 { color: var(--Gray40); }
  .colorGlobalGray50 { color: var(--Gray50); }
  .colorGlobalGray60 { color: var(--Gray60); }
  .colorGlobalGray70 { color: var(--Gray70); }
  .colorGlobalGray80 { color: var(--Gray80); }
  .colorGlobalGray90 { color: var(--Gray90); }
  .colorGlobalBlue10 { color: var(--Blue10); }
  .colorGlobalBlue30 { color: var(--Blue30); }
  .colorGlobalBlue60 { color: var(--Blue60); }
  .colorGlobalBlue70 { color: var(--Blue70); }
  .colorGlobalBlue80 { color: var(--Blue80); }
  .colorGlobalRed10 { color: var(--Red10); }
  .colorGlobalRed30 { color: var(--Red30); }
  .colorGlobalRed60 { color: var(--Red60); }
  .colorGlobalRed70 { color: var(--Red70); }
  .colorGlobalRed80 { color: var(--Red80); }
  .colorGlobalOrange10 { color: var(--Orange10); }
  .colorGlobalOrange30 { color: var(--Orange30); }
  .colorGlobalOrange60 { color: var(--Orange60); }
  .colorGlobalOrange70 { color: var(--Orange70); }
  .colorGlobalOrange80 { color: var(--Orange80); }
  .colorGlobalGreen10 { color: var(--Green10); }
  .colorGlobalGreen30 { color: var(--Green30); }
  .colorGlobalGreen60 { color: var(--Green60); }
  .colorGlobalGreen70 { color: var(--Green70); }
  .colorGlobalGreen80 { color: var(--Green80); }
  .colorGlobalGreenEECI10 { color: var(--GreenEECI10); }
  .colorGlobalGreenEECI30 { color: var(--GreenEECI30); }
  .colorGlobalGreenEECI60 { color: var(--GreenEECI60); }
  .colorGlobalGreenEECI70 { color: var(--GreenEECI70); }
  .colorGlobalGreenEECI80 { color: var(--GreenEECI80); }
  .colorGlobalYellow10 { color: var(--Yellow10); }
  .colorGlobalYellow30 { color: var(--Yellow30); }
  .colorGlobalYellow60 { color: var(--Yellow60); }
  .colorGlobalYellow70 { color: var(--Yellow70); }
  .colorGlobalYellow80 { color: var(--Yellow80); }
  .colorGlobalOrangeECI10 { color: var(--OrangeECI10); }
  .colorGlobalOrangeECI30 { color: var(--OrangeECI30); }
  .colorGlobalOrangeECI60 { color: var(--OrangeECI60); }
  .colorGlobalOrangeECI70 { color: var(--OrangeECI70); }
  .colorGlobalOrangeECI80 { color: var(--OrangeECI80); }
  .colorGlobalGreenECI10 { color: var(--GreenECI10); }
  .colorGlobalGreenECI30 { color: var(--GreenECI30); }
  .colorGlobalGreenECI60 { color: var(--GreenECI60); }
  .colorGlobalGreenECI70 { color: var(--GreenECI70); }
  .colorGlobalGreenECI80 { color: var(--GreenECI80); }
  .lightContent1 { color: var(--Content1); }
  .lightContent2 { color: var(--Content2); }
  .lightContent3 { color: var(--Content3); }
  .lightContentInverse { color: var(--ContentInverse); }
  .lightContentHover { color: var(--ContentHover); }
  .lightContentActive { color: var(--ContentActive); }
  .lightContentDisabled { color: var(--ContentDisabled); }
  .lightBackground1 { background: var(--Background1); }
  .lightBackground2 { background: var(--Background2); }
  .lightBackground3 { background: var(--Background3); }
  .lightBackgroundInverse { background: var(--BackgroundInverse); }
  .lightBackgroundHover { background: var(--BackgroundHover); }
  .lightBacgroundActive { background: var(--BacgroundActive); }
  .lightBackgroundDisabled { background: var(--BackgroundDisabled); }
  .lightBorder1 { border: 1px solid var(--Border1); }
  .lightBorder2 { border: 1px solid var(--Border2); }
  .lightBorder3 { border: 1px solid var(--Border3); }
  .lightBorderInverse { border: 1px solid var(--BorderInverse); }
  .lightSemanticHighlighting {
    color: var(--SemanticHighlighting);
    cursor: pointer;
    &:hover {
      color: var(--SemanticHighlightingHover);
      text-decoration: none;
    }
    &:active {
      color: var(--SemanticHighlightingActive);
    }
  }
  .lightSemanticWarning { color: var(--SemanticWarning); }
  .lightSemanticSuccess { color: var(--SemanticSuccess); }
  .lightSemanticDanger {
     color: var(--SemanticDanger);
     cursor: pointer;
     &:hover {
      color: var(--SemanticDangerHover);
      text-decoration: none;
    }
    &:active {
      color: var(--SemanticDangerActive);
    }
  }
  /* Typography Global */
  .fontFamilyPrimary { font-family: var(--FontFamilyPrimary); }
  .fontWeightRegular { font-weight: var(--FontWeightRegular); }
  .fontWeightMedium { font-weight: var(--FontWeightMedium); }
  .fontWeightBold { font-weight: bold };
  .fontSize1 { font-size: var(--FontSize1); }
  .fontSize2 { font-size: var(--FontSize2); }
  .fontSize3 { font-size: var(--FontSize3); }
  .fontSize4 { font-size: var(--FontSize4); }
  .fontSize5 { font-size: var(--FontSize5); }
  .fontSize6 { font-size: var(--FontSize6); }
  .fontSize7 { font-size: var(--FontSize7); }
  .fontSize8 { font-size: var(--FontSize8); }
  .fontSize9 { font-size: var(--FontSize9); }
  .fontSize10 { font-size: var(--FontSize10); }
  .fontSize11 { font-size: var(--FontSize11); }
  .fontSize12 { font-size: var(--FontSize12); }
  .fontSize13 { font-size: var(--FontSize13); }
  .fontSize14 { font-size: var(--FontSize14); }
  .fontSize15 { font-size: var(--FontSize15); }
  .fontSize16 { font-size: var(--FontSize16); }

  .marginTopSize1 { margin-top: var(--Size1); }
  .marginTopSize2 { margin-top: var(--Size2); }
  .marginTopSize3 { margin-top: var(--Size3); }
  .marginTopSize4 { margin-top: var(--Size4); }
  .marginTopSize5 { margin-top: var(--Size5); }
  .marginTopSize6 { margin-top: var(--Size6); }
  .marginTopSize7 { margin-top: var(--Size7); }
  .marginTopSize8 { margin-top: var(--Size8); }
  .marginTopSize9 { margin-top: var(--Size9); }
  .marginTopSize10 { margin-top: var(--Size10); }
  .marginTopSize11 { margin-top: var(--Size11); }
  .marginTopSize12 { margin-top: var(--Size12); }
  .marginBottomSize1 { margin-bottom: var(--Size1); }
  .marginBottomSize2 { margin-bottom: var(--Size2); }
  .marginBottomSize3 { margin-bottom: var(--Size3); }
  .marginBottomSize4 { margin-bottom: var(--Size4); }
  .marginBottomSize5 { margin-bottom: var(--Size5); }
  .marginBottomSize6 { margin-bottom: var(--Size6); }
  .marginBottomSize7 { margin-bottom: var(--Size7); }
  .marginBottomSize8 { margin-bottom: var(--Size8); }
  .marginBottomSize9 { margin-bottom: var(--Size9); }
  .marginBottomSize10 { margin-bottom: var(--Size10); }
  .marginBottomSize11 { margin-bottom: var(--Size11); }
  .marginBottomSize12 { margin-bottom: var(--Size12); }

    /* Object Style */
  .borderRadiusS { border-radius: var(--BorderRadiusS); }
  .borderRadiusM { border-radius: var(--BorderRadiusM); }
  .borderRadiusL { border-radius: var(--BorderRadiusL); }
  .borderRadiusXL { border-radius: var(--BorderRadiusXL); }
  .borderRadiusFull { border-radius: var(--BorderRadiusFull); }

    /* Sizing & Spacing */
  .paddingSize1 { padding: var(--Size1); }
  .paddingSize2 { padding: var(--Size2); }
  .paddingSize3 { padding: var(--Size3); }
  .paddingSize4 { padding: var(--Size4); }
  .paddingSize5 { padding: var(--Size5); }
  .paddingSize6 { padding: var(--Size6); }
  .paddingSize7 { padding: var(--Size7); }
  .paddingSize8 { padding: var(--Size8); }
  .paddingSize9 { padding: var(--Size9); }
  .paddingSize10 { padding: var(--Size10); }
  .paddingSize11 { padding: var(--Size11); }
  .paddingSize12 { padding: var(--Size12); }

  .bodyS {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightRegular);
    font-size: var(--FontSize2);
    line-height: 16px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .bodyM {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightRegular);
    font-size: var(--FontSize3);
    line-height: 20px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .bodyL {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightRegular);
    font-size: var(--FontSize4);
    line-height: 28px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .buttonM {
    font-family: var(--FontFamilyPrimary);
    //font-weight: var(--FontWeightRegular);
    font-size: var(--FontSize4);
    line-height: 18px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .headingXS {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightMedium);
    font-size: var(--FontSize4);
    line-height: 24px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .headingS {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightMedium);
    font-size: var(--FontSize4);
    line-height: 28px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .headingM {
    font-family: var(--FontFamilyPrimary);
    // font-weight: var(--FontWeightMedium);
    font-size: var(--FontSize7);
    line-height: 36px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .headingL {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightRegular);
    font-size: var(--FontSize10);
    line-height: 48px;
    letter-spacing: var(--LetterSpacingDefault);
  }

  .headingXL {
    font-family: var(--FontFamilyPrimary);
    font-weight: var(--FontWeightRegular);
    font-size: var(--FontSize11);
    line-height: 48px;
    letter-spacing: var(--LetterSpacingNarrowest);
  }

  .heading2XL {
    font-family: var(--FontFamilyPrimary);
    //font-weight: var(--FontWeightMedium);
    font-size: var(--FontSize13);
    line-height: 68px;
    letter-spacing: var(--LetterSpacingNarrowest);
  }

  .margin-right-4 {
    margin-right: var(--Size4);
  }

  .content1 {
    color: var(--Content1);
  }

  .content2 {
    color: var(--Content2);
  }

  a {
      color: var(--GreenEECI70);
      text-decoration: none;
      background-color: transparent;
  }

  .buttons {
    padding: var(--Size4) var(--Size7);
  // border-radius: var(--BorderRadiusXL);
    border-radius: var(--BorderRadiusEECI);
    font-size: var(--FontSize4);
    line-height: var(--Size4);
    font-weight: 600;
    border: 0;
    &.primary {
      color: var(--Gray90);
      background: var(--GreenEECI70);
      &:hover {
        background: var(--GreenEECI60);
      }
      &:active {
        background: var(--GreenEECI80);
      }
      &:disabled {
        background-color: var(--GreenEECI30);
        color: var(--Gray10);
      }
    }
    &.secondary {
      color: var(--GreenEECI70);
      background: var(--BasicWhite);
      border: 1px solid var(--GreenEECI70);
      box-shadow: var(--ShadowM);
      &:hover {
        color: var(--GreenEECI60);
        background: var(--BasicWhite);
        border: 1px solid var(--GreenEECI60);
      }
      &:active {
        color: var(--GreenEECIECI80);
        background: var(--BasicWhite);
        border: 1px solid var(--GreenEECI80);
      }
    }
    &.tertiary {
      color: var(--Gray70);
      background: var(--Background1);
      border: 1px solid #D9D9D9;
      box-shadow: var(--ShadowM);
      &:active {
        color: var(--Gray90);
        background: var(--Background1);
        border: 1px solid var(--Gray50);
      }
    }
    &.tertiaryIcon {
      padding: var(--Size4) var(--Size7);
      color: var(--Gray70);
      background: var(--Background1);
      border: 1px solid #D9D9D9;
      box-shadow: var(--ShadowM);
      &:hover {
        color: var(--Content3);
        background: var(--Background1);
      }
      &:active {
        color: var(--Content1);
        background: var(--Background1);
        border: 1px solid var(--Gray50);
      }
      img {
        height: 20px;
      }
    }
    &.secondaryIcon {
      padding: var(--Size3) var(--Size7);
      color: var(--GreenEECI70);
      background: var(--BasicWhite);
      border: 1px solid var(--GreenEECI70);
      box-shadow: var(--ShadowM);
      &:hover {
        color: var(--GreenEECI60);
        background: var(--BasicWhite);
        border: 1px solid var(--GreenEECI60);
      }
      &:active {
        color: var(--GreenEECI80);
        background: var(--BasicWhite);
        border: 1px solid var(--GreenEECI80);
      }
    }
    &.primaryVariacion {
      color: var(--Gray90);
      background: var(--GreenEECI70);
      &:hover {
        background: var(--GreenEECI60);
        color: var(--BasicBlack) !important;
      }
      &:active {
        background: var(--GreenEECI80);
      }
      &.disabled {
        background-color: var(--GreenEECI30);
        color: var(--Gray40);
      }
    }
    &.buttons-lg{
      padding: .5rem 1rem;
    }
    &.btnDanger{
      color: var(--Red60);	
      background: var(--BasicWhite);	
      border: 1px solid var(--Red60);	
      box-shadow: var(--ShadowM);	
      &:hover {
        color: var(--Red80);	
        background: var(--BasicWhite);	
        border: 1px solid var(--Red80);	
      }
    }

  .ngdialog.ngdialog-theme-default .ngdialog-content {
    background-color: var(--BasicWhite);
  }
 
  .ngdialog-content {
    background-color: var(--BasicWhite);
  }

.ngdialog.ngdialog-theme-default.ng-dialog-w-60 .ngdialog-content {
  width: 60%;
}
.ngdialog.ngdialog-theme-default.ng-dialog-w-70 .ngdialog-content {
  width: 70%;
}
.ngdialog.ngdialog-theme-default.ng-dialog-w-80 .ngdialog-content {
  width: 80%;
}
.ngdialog.ngdialog-theme-default .ngdialog-content .dialogContent{	
  padding: 30px 80px 60px;	
}


  .input-sweno {
    width: 100%;
    background: var(--Gray30);
    border-radius: 8px;
    color: var(--Content3);
    border: 1px solid var(--Gray30);
    padding: 0px 16px;
    font-size: 14px;
    height: 42px;
  }

  .alert-OrangeECI30 {
    background: var(--OrangeECI30);
    border-radius: 8px;
    padding: 12px;
  }


  a{
    color: var(--GreenEECI70);
    outline: none;
    &:hover {
      text-decoration: none;
      color: var(--GreenEECI60) !important;
    }
  }

  .navbar-light .nav-item a:hover,
  footer a:hover {
    color: var(--GreenEECI70) !important;
  }
  .w-100{
    width: 100%;
  }
  .line-1{
    line-height: 1em;
  }
}
h1{margin: 0;}

#footer{
  margin-bottom: 92px;
}

.ngdialog-content {
  &.ngDialog-w-30 {
    width: 30% !important;
  }
  &.ngDialog-w-60 {
    width: 60% !important;
  }
  &.ngDialog-w-70 {
    width: 70% !important;
  }
  &.ngDialog-w-80 {
    width: 80% !important;
  }
}

.ngdialog-responsive .ngdialog-content {
  width: 60% !important;
}
.ngdialog-te-llamamos .ngdialog-content {
  width: 500px !important;
}

.direccion{	
  overflow: hidden;	
  display: inline-block;	
  white-space: nowrap;	
  text-overflow: ellipsis;	
}	
.content-cliente{	
  padding-top: 190px;	
  padding-bottom: 70px;	
  // min-height: 65vh;	
  .cliente-blq{	
    background: #FFFFFF;	
    border: 1px solid #D9D9D9;	
    box-sizing: border-box;	
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);	
    border-radius: 8px;	
    padding: 30px;	
    &.blq-xl{ padding: 50px 100px}	
  }	
  .neutral{	
    color: #88868D;	
  }	
  .div-info{	
    background: #FFF0E8;	
    border-radius: 8px;	
    padding: 20px 30px 20px 20px;	
  }	
}	
.ic-info{	
  width: 16px;	
}	
.infoFlotante {	
  position: absolute;	
  background: #FFFFFF;	
  border: 2px solid rgba(0, 0, 0, 0.1);	
  box-sizing: border-box;	
  box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.02);	
  border-radius: 8px;	
  padding: 16px;	
  margin-right: 100px;	
  width: 298px;	
  z-index: 11;	
}
#footer {
    margin-bottom: 0 !important;	
    .fontSize2.py-3{padding-bottom: 0 !important}	
}	

@media (min-width:768px) and (max-width:991px) {
  .headingXL{
    font-size: var(--FontSize9) !important;
  }
  .ngdialog-responsive .ngdialog-content {
    width: 80% !important;
  }
}
@media (max-width:767px) {
  .headingXL,
  .headingL {
    font-size: var(--FontSize9) !important;
  }
  .ngdialog-theme-default .ngdialog-content,
  .ngdialog-responsive .ngdialog-content
   {
    width: 95% !important;
  }
  .ngdialog-te-llamamos .ngdialog-content {
    width: 95% !important;
    padding: 0 0 20px 0 !important;
  }
  .ngdialog .buttons {
    margin-bottom: 20px;
  }
  header .navbar-brand {
    left: 75px !important;
    top: 22px !important;
  }
  .content-cliente{	
    padding-top: 150px;	
    .direc-mobile{	
      border: 1px solid #D9D9D9;	
      border-radius: 8px;	
      padding: 10px;	
      cursor: pointer;	
    }	
    .cliente-blq{	
      &.blq-xl{ padding: 30px}	
    }	
  }	
  body .buttons, body .w-50, body .w-75{ width: 100% !important}	
  .ngdialog.ngdialog-theme-default .ngdialog-content .dialogContent{	
    padding: 10px !important;	
  }	
  .infoFlotante{	
    width: 270px !important;	
  }
}
}